<template>
  <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
    <form v-on:submit.prevent="handleSubmit(next)" class="p-4">
      <div class="row">        
        <div class="col-md-12">
          <div class="row">

              <div class="col-md-2 col-12">
              <div class="form-group">
                <label>Salutation</label>
                <select
                  name="salutation"
                  v-model="newApplication.salutation"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Mr.">Mr</option>
                  <option value="Mrs.">Mrs</option>
                  <option value="Dr.">Dr</option>
                  <option value="Engr.">Engineer</option>
                </select>
              </div>
            </div>

            <div class="col-md-5 col-12">
              <ValidationProvider
                vid="first_name"
                name="First Name"
                :rules="{
                  required: true,
                  alpha_spaces: true,
                  min: 3,
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>First Name</label>
                  <CInput
                    type="text"
                    v-model="newApplication.first_name"
                    placeholder="Enter customer first name"
                    required
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-5 col-12">
              <ValidationProvider
                vid="last_name"
                name="Last Name"
                :rules="{
                  required: true,
                  alpha_spaces: true,
                  min: 3,
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Last Name</label>
                  <CInput
                    type="text"
                    v-model="newApplication.last_name"
                    placeholder="Enter customer last name"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    required
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-4 col-12">
              <ValidationProvider
                vid="father_name"
                name="Father's Name"
                :rules="{
                  required: true,
                  alpha_spaces: true,
                  min: 3,
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Father's Name</label>
                  <CInput
                    type="text"
                    v-model="newApplication.father_name"
                    placeholder="Enter father's name"
                    required
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-4 col-12">
              <ValidationProvider
                vid="mother_name"
                name="Mother's Name"
                :rules="{
                  required: false,
                  alpha_spaces: true,
                  min: 3,
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Mother's Name</label>
                  <CInput
                    type="text"
                    v-model="newApplication.mother_name"
                    placeholder="Enter mother's name"                    
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-4 col-12">
              <ValidationProvider
                vid="spouse_name"
                name="Spouse Name"
                :rules="{
                  required: false,
                  alpha_spaces: true,
                  min: 3,
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Spouse Name</label>
                  <CInput
                    type="text"
                    v-model="newApplication.spouse_name"
                    placeholder="Enter spouse name"                    
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Connection Durability</label>
                <select
                  name="category"
                  v-model="newApplication.connection_duration"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Permanent">Permanent</option>
                  <option value="Temporary">Temporary</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Has any previous connection?</label> <br>               

                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="newApplication.has_previous_connection" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="true">
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="newApplication.has_previous_connection" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="false">
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Category</label>
                <select
                  name="category"
                  v-model="newApplication.category"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Domestic">Domestic</option>
                  <option value="Industrial">Industrial</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Connection Type</label>
                <select
                  name="connection_type"
                  v-model="newApplication.connection_type"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="0.50 INCH">0.50 INCH</option>
                  <option value="0.75 INCH">0.75 INCH</option>
                  <option value="1.00 INCH">1.00 INCH</option>
                </select>
              </div>
            </div>

            

            <div class="col-md-4">
              <ValidationProvider
                name="Email"
                vid="email"
                :rules="{ required: true, email: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Email</label>
                  <CInput
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    type="email"
                    v-model="newApplication.email"
                    placeholder="Email address"
                    required
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                vid="mobile_number"
                name="Mobile Number"
                :rules="{
                  required: true,
                  numeric: true,
                  min: 11,
                  digits: 11,
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Mobile Number</label>
                  <CInput
                    type="text"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    v-model="newApplication.mobile_number"
                    placeholder="Mobile number"
                    required
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                vid="account_number"
                name="Account Number"
                :rules="{
                  required: true,                 
                }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Account Number</label>
                  <CInput
                    type="text"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    v-model="newApplication.account_number"
                    placeholder="Account number"
                    required
                  />
                  <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

          </div>
        </div>
      </div>

      <hr />

      <!-- <button type="button" class="btn btn-default btn-lg">
        Back
      </button> -->
      <div class="row">
          <div class="col-md-12 text-right">
            <button type="submit" class="btn btn-primary">
                Next
            </button>
          </div>
      </div>
      
    </form>
  </validation-observer>
</template>
<script>
export default {
  name: "PrimaryInfo",
  props: {
    application: Object,
    activeTab: Number,
  },
  data() {
    return {
      newApplication: {},
    };
  },
  methods: {    
    next() {        
        this.$emit('formSubmitted', {data: this.newApplication, tab: 2})
    }
  },
  mounted() {
    this.newApplication = { ...this.application };
  },
};
</script>
